
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const GET_BUSINESS_HOURS = "/setting/get-business-hours"
export const POST_UPDATE_BUSINESS_HOURS_STATUS = "/setting/update-business-hours-status"
export const DELETE_BUSINESS_HOURS = "/setting/delete"
export const POST_ADD_EDIT_BUSINESS_HOURS = "/setting/add-edit-business-hours"
export const POST_BUSINESS_HOURS_DETAIL = "/setting/get-business-hours-detail"
export const POST_UPDATE_SETTING = "/setting/update-setting"
export const POST_UPDATE_SETTING_MENU = "setting/update-menu"

//admin
export const POST_ADMIN_USER_LIST = process.env.REACT_APP_API_URL + "admin/get-all-user"
export const DELETE_ADMIN_USER = "/admin/delete"
export const POST_UPDATE_ADMIN_USER_STATUS = "/admin/update-status"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/detail"
export const GET_ADMIN_FORM_OPTION = "/admin/get-form-option"

//Users
export const POST_USERS_LIST = process.env.REACT_APP_API_URL + "users/get-all-user"
export const DELETE_USERS = "/users/delete"
export const POST_UPDATE_USERS_STATUS = "/users/update-status"
export const POST_ADD_EDIT_USERS = "/users/add-edit"
export const POST_GET_USERS_DETAIL = "/users/detail"

//user role
export const POST_ROLE_LIST = process.env.REACT_APP_API_URL + "user-roles/get-all-user-role"
export const DELETE_ROLE = "/user-roles/delete"
export const POST_UPDATE_ROLE_STATUS = "/user-roles/update-status"
export const POST_ADD_EDIT_ROLE = "/user-roles/add-edit"
export const POST_GET_ROLE_DETAIL = "/user-roles/detail"

//category
export const POST_CATEGORY_LIST = process.env.REACT_APP_API_URL + "category/get-all-category"
export const DELETE_CATEGORY = "/category/delete"
export const POST_UPDATE_CATEGORY_STATUS = "/category/update-status"
export const POST_ADD_EDIT_CATEGORY = "/category/add-edit"
export const POST_GET_CATEGORY_DETAIL = "/category/detail"
export const POST_GET_CATEGORY_FORM_OPTION = "/category/get-form-option"

//products
export const POST_PRODUCT_LIST = process.env.REACT_APP_API_URL + "products/get-all-product"
export const DELETE_PRODUCT = "/products/delete"
export const POST_UPDATE_PRODUCT_STATUS = "/products/update-status"
export const POST_ADD_EDIT_PRODUCT = "/products/add-edit"
export const POST_GET_PRODUCT_DETAIL = "/products/detail"
export const POST_GET_CATEGORY = "/products/get-categories"

//voucher
export const POST_VOUCHER_LIST = process.env.REACT_APP_API_URL + "voucher/get-all-voucher"
export const DELETE_VOUCHER = "/voucher/delete"
export const POST_UPDATE_VOUCHER_STATUS = "/voucher/update-status"
export const POST_ADD_EDIT_VOUCHER = "/voucher/add-edit"
export const POST_GET_VOUCHER_DETAIL = "/voucher/detail"

//order
export const POST_GET_ALL_ORDER_LIST = process.env.REACT_APP_API_URL + "order/get-all-order"
export const POST_GET_DETAIL = "/order/detail"
export const POST_UPDATE_ORDER_STATUS = "/order/update-order-payment-status"

//user-vouchers
export const POST_GET_ALL_USER_VOUCHERS_LIST = process.env.REACT_APP_API_URL + "user-vouchers/get-all-voucher"
export const POST_UPDATE_USER_VOUCHERS_STATUS = "/user-vouchers/update-voucher-status"
export const POST_GET_ALL_COUPON_CODE_LIST = process.env.REACT_APP_API_URL+"user-vouchers/get-all-coupon-code"
export const POST_GET_COUPON_CODE_DETAIL = "/user-vouchers/detail"
export const POST_GET_COUPON_CODE_ADD_EDIT = "/user-vouchers/add-edit"



//table book
export const POST_GET_ALL_TABLE_BOOKING_LIST = process.env.REACT_APP_API_URL + "table-booking/get-table-booking"
export const POST_GET_DETAIL_TABLE_BOOKING = "/table-booking/detail"
export const POST_UPDATE_TABLE_BOOKING_STATUS = "/table-booking/update-table-booking-status"
export const GET_TABLE_BOOKING_TIME = "/time/get-table-booking-time"
export const POST_BOOK_TABLE = "/table-booking/book-table"
export const POST_GET_PAYMENT = "/table-booking/get-payment"

//function-booking
export const POST_GET_ALL_FUNCTION_BOOKING_LIST = process.env.REACT_APP_API_URL + "function-booking/get-function-booking"
export const POST_GET_DETAIL_FUNCTION_BOOKING = "/function-booking/detail"
export const POST_UPDATE_FUNCTION_BOOKING_STATUS = "/function-booking/update-function-booking-status"
export const GET_FUNCTION_BOOKING_TIME = "/time/get-functions-booking-time"

//Bottomless Brunch
export const POST_GET_ALL_BOTTOMLESS_BRUNCH_LIST = process.env.REACT_APP_API_URL + "bottomless-brunch/get-booking-detail"
export const POST_GET_DETAIL_BOTTOMLESS_BRUNCH = "/bottomless-brunch/detail"
export const POST_UPDATE_BOTTOMLESS_BRUNCH_STATUS = "/bottomless-brunch/update-booking-status"
export const GET_BOTTOMLESS_BRUNCH_TIME = "/time/get-bottomless-brunch-time"

//banner
export const POST_BANNER_LIST = process.env.REACT_APP_API_URL + "banner/get-all-banner"
export const DELETE_BANNER = "/banner/delete"
export const POST_UPDATE_BANNER_STATUS = "/banner/update-status"
export const POST_ADD_BANNER = "/banner/add"
export const POST_GET_BANNER_DETAIL = "/banner/detail"

// common
export const GET_TIME_SLOT = "/common/get-time-slot"
export const POST_CHECK_AVAILABLE_TABLE = "/common/check-available-table"

// jobs
export const GET_JOBS_DETAILS = process.env.REACT_APP_API_URL + "jobs/detail"
export const GET_ALL_JOBS = process.env.REACT_APP_API_URL + "jobs/get-all-jobs"
export const ADD_EDIT_JOBS = process.env.REACT_APP_API_URL + "jobs/add-edit"
export const JOBS_DELETE = process.env.REACT_APP_API_URL + "jobs/delete"
export const JOBS_UPDATE = process.env.REACT_APP_API_URL + "jobs/update-status"
export const GET_ALL_APPLICATIONS = process.env.REACT_APP_API_URL + "applications/get-all-applications"
export const GET_APPLICATION_DETAIL = process.env.REACT_APP_API_URL + "applications/detail"
export const DELETE_APLLICATION = process.env.REACT_APP_API_URL + "applications/delete"