import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, getUserId } from '../../helpers/Helper';
import { ADD_EDIT_JOBS, GET_ALL_JOBS, GET_JOBS_DETAILS, JOBS_DELETE, JOBS_UPDATE } from '../../helpers/url_helper';

export default function JobListings() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editJob, setEditJob] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        type: '',
        department: '',
        description: '',
        posted_on: '',
        application_closing: '',
    });

    const userId = getUserId();
    const config = {
        headers: { 'Authorization': getToken() }
    };


    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.post(GET_ALL_JOBS, {
                iDisplayLength: 10,
                iDisplayStart: 0,
                sSearch: '',
                sSortDir_0: 'desc',
                iSortCol_0: 0
            }, config);

            if (response.data?.status && Array.isArray(response.data?.aaData)) {
                setJobs(response.data.aaData);

            } else {
                console.error('Invalid response structure:', response.data);
                setJobs([]);
                setError('Invalid data received from server');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setJobs([]);
            setError('Failed to fetch jobs');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                ...formData,
                login_user_id: userId,
                job_id: editJob?.job_id
            };

            await axios.post(ADD_EDIT_JOBS, payload, config);

            setFormData({
                title: '',
                type: '',
                department: '',
                description: '',
                posted_on: '',
                application_closing: '',
            });
            setEditJob(null);
            fetchJobs();
            alert(editJob ? 'Job updated successfully' : 'Job added successfully');
        } catch (error) {
            console.error('Error saving job:', error);
            alert('Failed to save job');
        }
    };

    const handleEdit = async (jobId) => {
        try {
            const response = await axios.post(GET_JOBS_DETAILS, {
                job_id: jobId
            }, config);

            if (response.data.status) {
                const job = response.data.detail;
                setEditJob(job);
                setFormData({
                    title: job.title,
                    type: job.type,
                    department: job.department,
                    description: job.description,
                    posted_on: job.posted_on,
                    application_closing: job.application_closing
                });
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
            alert('Failed to fetch job details');
        }
    };

    const handleDelete = async (jobId) => {
        if (window.confirm('Are you sure you want to delete this job?')) {
            try {
                await axios.delete(`${JOBS_DELETE}?job_id=${jobId}`, {
                    ...config,
                    data: { login_user_id: userId }
                });
                fetchJobs();
                alert('Job deleted successfully');
            } catch (error) {
                console.error('Error deleting job:', error);
                alert('Failed to delete job');
            }
        }
    };

    const handleStatusUpdate = async (jobId, currentStatus) => {
        try {
            await axios.post(JOBS_UPDATE, {
                job_id: jobId,
                status: currentStatus,
                login_user_id: userId
            }, config);
            fetchJobs();
            alert('Status updated successfully');
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Failed to update status');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">Job Listings Management</h1>

            {/* Add/Edit Form */}
            <div className="card mb-4">
                <div className="card-header">
                    <h5 className="mb-0">{editJob ? 'Edit Job' : 'Add New Job'}</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Posted on</label>
                            <input
                                type="date"
                                className="form-control"
                                name="posted_on"
                                value={formData.posted_on}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Application Closing</label>
                            <input
                                type="date"
                                className="form-control"
                                name="application_closing"
                                value={formData.application_closing}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Type</label>
                            <input
                                type="text"
                                className="form-control"
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Department</label>
                            <input
                                type="text"
                                className="form-control"
                                name="department"
                                value={formData.department}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                rows="4"
                                required
                            />
                        </div>
                        <div className="d-flex gap-2">
                            <button type="submit" className="btn mr-2 btn-primary">
                                {editJob ? 'Update Job' : 'Add Job'}
                            </button>
                            {editJob && (
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setEditJob(null);
                                        setFormData({
                                            title: '',
                                            type: '',
                                            department: '',
                                            description: '',
                                            posted_on: '',
                                            application_closing: '',
                                        });
                                    }}
                                >
                                    Cancel Edit
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>

            {/* Jobs Table */}
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0">Job Listings</h5>
                </div>
                <div className="card-body">
                    {loading && (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}

                    {!loading && !error && (
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Posted on</th>
                                        <th>Application Closing</th>
                                        <th>Type</th>
                                        <th>Department</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobs.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No jobs found
                                            </td>
                                        </tr>
                                    ) : (
                                        jobs.map((job) => (
                                            <tr key={job.job_id}>
                                                <td>{job.title}</td>
                                                <td>{formatDate(job.posted_on)}</td>
                                                <td>{formatDate(job.application_closing)}</td>
                                                <td>{job.type}</td>
                                                <td>{job.department}</td>
                                                <td>
                                                    <button
                                                        className={`btn badge btn-sm ${job.status === 1 ? 'btn-success' : 'btn-secondary'}`}
                                                        onClick={() => handleStatusUpdate(job.job_id, job.status)}
                                                    >
                                                        {job.status === 1 ? 'Active' : 'Inactive'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <button
                                                            className="btn badge mr-2 btn-sm btn-primary"
                                                            onClick={() => handleEdit(job.job_id)}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="btn badge btn-sm btn-danger"
                                                            onClick={() => handleDelete(job.job_id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}