import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DELETE_APLLICATION, GET_ALL_APPLICATIONS, GET_APPLICATION_DETAIL } from '../../helpers/url_helper';
import { getToken } from '../../helpers/Helper';

export default function UserApplications() {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [downloadingResume, setDownloadingResume] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const config = {
        headers: {
            'Authorization': getToken(),
            'Content-Type': 'application/json'
        }
    };

    const fetchApplications = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.post(GET_ALL_APPLICATIONS, {
                iDisplayLength: 100,
                iDisplayStart: 0,
                sSearch: '',
                sSortDir_0: 'desc',
                iSortCol_0: 0
            }, config);

            if (response.data?.status && Array.isArray(response.data?.aaData)) {
                setApplications(response.data.aaData);
            } else {
                console.error('Invalid response structure:', response.data);
                setApplications([]);
                setError('Invalid data received from server');
            }
        } catch (err) {
            console.error('Error fetching applications:', err);
            setApplications([]);
            setError('An error occurred while fetching applications');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApplications();
    }, []);

    const viewApplicationDetail = async (applicationId) => {
        try {
            setModalError(null);
            setIsModalOpen(true);

            const response = await axios.post(GET_APPLICATION_DETAIL, {
                application_id: applicationId
            }, config);

            if (response.data?.status && response.data?.detail) {
                setSelectedApplication(response.data.detail);
            } else {
                setModalError('Failed to load application details');
                console.error('Invalid detail response:', response.data);
            }
        } catch (err) {
            console.error('Error fetching application detail:', err);
            setModalError('Error loading application details');
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedApplication(null);
        setModalError(null);
    };

    const viewResumeInNewTab = (resumePath) => {
        if (!resumePath) {
            alert('Resume path is not available');
            return;
        }
        const baseUrl = process.env.REACT_APP_IMG_URL || '';
        const fullPath = `${baseUrl}uploads/${resumePath}`;
        window.open(fullPath, '_blank', 'noopener,noreferrer');
    };

    const handleDelete = async (applicationId) => {
        if (!window.confirm('Are you sure you want to delete this application?')) {
            return;
        }

        try {
            setDeleteLoading(true);
            const response = await axios.post(DELETE_APLLICATION, {
                application_id: applicationId
            }, config);

            if (response.data?.status) {
                // Remove the deleted application from the state
                setApplications(prev => prev.filter(app => app.application_id !== applicationId));
                alert('Application deleted successfully');
            } else {
                alert(response.data?.message || 'Failed to delete application');
            }
        } catch (err) {
            console.error('Error deleting application:', err);
            alert('An error occurred while deleting the application');
        } finally {
            setDeleteLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger m-3" role="alert">
                {error}
            </div>
        );
    }

    return (
        <div className="container-fluid py-4">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Job Applications</h5>
                </div>
                <div className="card-body">
                    {applications.length === 0 ? (
                        <div className="text-center py-4">
                            <p className="text-muted">No applications found</p>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applications.map((application) => (
                                        <tr key={application.application_id}>
                                            <td>{application.full_name || 'N/A'}</td>
                                            <td>{application.job_title || 'N/A'}</td>
                                            <td>{application.email || 'N/A'}</td>
                                            <td>{application.contact || 'N/A'}</td>
                                            <td>
                                                <div className="btn-group">
                                                    <button
                                                        className="btn btn-outline-primary btn-sm mr-2"
                                                        onClick={() => viewApplicationDetail(application.application_id)}
                                                    >
                                                        <i className="bi bi-eye me-1"></i>
                                                        View
                                                    </button>
                                                    <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={() => handleDelete(application.application_id)}
                                                        disabled={deleteLoading}
                                                    >
                                                        <i className="bi bi-trash me-1"></i>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {/* Application Detail Modal */}
                    <div className={`modal fade ${isModalOpen ? 'show' : ''}`}
                        style={{ display: isModalOpen ? 'block' : 'none' }}
                        tabIndex="-1"
                        aria-hidden={!isModalOpen}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Application Details</h5>
                                </div>
                                <div className="modal-body">
                                    {modalError ? (
                                        <div className="alert alert-danger">{modalError}</div>
                                    ) : selectedApplication ? (
                                        <div>
                                            <div className="mb-3">
                                                <h6 className="fw-bold">Personal Information</h6>
                                                <p className="mb-1">Name: {selectedApplication.full_name || 'N/A'}</p>
                                                <p className="mb-1">Email: {selectedApplication.email || 'N/A'}</p>
                                                <p className="mb-1">Contact: {selectedApplication.contact || 'N/A'}</p>
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="fw-bold">Job Information</h6>
                                                <p className="mb-1">Position: {selectedApplication.job_title || 'N/A'}</p>
                                                <p className="mb-1">Department: {selectedApplication.department || 'N/A'}</p>
                                                <p className="mb-1">
                                                    Applied on: {selectedApplication.created_at
                                                        ? new Date(selectedApplication.created_at).toLocaleDateString()
                                                        : 'N/A'}
                                                </p>
                                            </div>
                                            {selectedApplication.resume_path && (
                                                <div>
                                                    <h6 className="fw-bold">Resume</h6>
                                                    <div className="btn-group">
                                                        <button
                                                            onClick={() => viewResumeInNewTab(selectedApplication.resume_path)}
                                                            className="btn btn-outline-primary btn-sm me-2"
                                                            disabled={downloadingResume}
                                                        >
                                                            <i className="bi bi-eye me-1"></i>
                                                            View Resume
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="text-center py-4">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button onClick={handleModalClose} type="button" className="btn btn-secondary">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isModalOpen && <div className="modal-backdrop fade show"></div>}
                </div>
            </div>
        </div>
    );
}