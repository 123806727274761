import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//Admin
import AdminList from "./pages/Admin/AdminList";
import AdminForm from "./pages/Admin/AdminForm";

//users
import UsersList from "./pages/Users/UsersList";

//User Role
import UserRolesList from "./pages/UserRoles/UserRolesList";
import UserRolesForm from "./pages/UserRoles/UserRolesForm";

//User Category
import CategoryList from "./pages/Categories/CategoryList";
import CategoryForm from "./pages/Categories/CategoryForm";

//vouchers
import VoucherList from "./pages/Voucher/VoucherList";
import VoucherForm from "./pages/Voucher/VoucherForm";

import UserGiftVouchers from "./pages/UserGiftVouchers/GiftVouchersList";

import CouponCodeList from "./pages/CouponCode/CouponCodeList";
import CouponCodeForm from "./pages/CouponCode/CouponCodeForm";

//product
import ProductsList from "./pages/Products/ProductsList";
import ProductsForm from "./pages/Products/ProductsForm";

//User Banner
import BannerList from "./pages/Banner/BannerList";
import BannerForm from "./pages/Banner/BannerForm";

//order
import OrderList from "./pages/Order/OrderList";

//Table Booking
import TableBookingList from "./pages/TableBooking/TableBookingList";
import TableBookingForm from "./pages/TableBooking/TableBookingForm";

//Functions Booking
import FunctionsBookingList from "./pages/FunctionsBooking/FunctionsBookingList";
import FunctionsBookingForm from "./pages/FunctionsBooking/FunctionsBookingForm";

//Bottomless Brunch
import BottomlessBrunchList from "./pages/BottomlessBrunch/BottomlessBrunchList";
import BottomlessBrunchForm from "./pages/BottomlessBrunch/BottomlessBrunchForm";

// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";
import BusinessHours from "./pages/Setting/BusinessHours";
import AddEditBusinessHoursForm from "./pages/Setting/AddEditBusinessHoursForm";
import MenuSetting from "./pages/Setting/MenuSetting";
import TableBooking from "./pages/Setting/TableBooking";

// jobs
import UserApplications from './pages/Jobs/user_applications';
import JobsListings from './pages/Jobs/Jobs_listings';


function App() {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path="/login" component={Login} layout={Full} />
                    <AppRoute exact path="/logout" component={Logout} layout={Full} />
                    <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />

                    <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                    <UserRoute exact path="/admin" component={AdminList} layout={Default} />
                    <UserRoute exact path="/admin/add" component={AdminForm} layout={Default} />
                    <UserRoute exact path="/admin/edit/:id?" component={AdminForm} layout={Default} />
                    
                    {/* user role */}
                    <UserRoute exact path="/user-role" component={UserRolesList} layout={Default} />
                    <UserRoute exact path="/user-role/add" component={UserRolesForm} layout={Default} />
                    <UserRoute exact path="/user-role/edit/:id?" component={UserRolesForm} layout={Default} />

                    {/* Category */}
                    <UserRoute exact path="/categories" component={CategoryList} layout={Default} />
                    <UserRoute exact path="/categories/add" component={CategoryForm} layout={Default} />
                    <UserRoute exact path="/categories/edit/:id?" component={CategoryForm} layout={Default} />

                    {/* Voucher */}
                    <UserRoute exact path="/vouchers" component={VoucherList} layout={Default} />
                    <UserRoute exact path="/vouchers/add" component={VoucherForm} layout={Default} />
                    <UserRoute exact path="/vouchers/edit/:id?" component={VoucherForm} layout={Default} />

                    <UserRoute exact path="/user-vouchers" component={UserGiftVouchers} layout={Default} />
                    <UserRoute exact path="/coupon-code" component={CouponCodeList} layout={Default} />
                    <UserRoute exact path="/coupon-code/add" component={CouponCodeForm} layout={Default} />
                    <UserRoute exact path="/coupon-code/edit/:id?" component={CouponCodeForm} layout={Default} />

                    {/* products */}
                    <UserRoute exact path="/products" component={ProductsList} layout={Default} />
                    <UserRoute exact path="/products/add" component={ProductsForm} layout={Default} />
                    <UserRoute exact path="/products/edit/:id?" component={ProductsForm} layout={Default} />

                    {/* user */}
                    <UserRoute exact path="/users" component={UsersList} layout={Default} />

                    {/* user role */}
                    <UserRoute exact path="/user-role" component={UserRolesList} layout={Default} />
                    <UserRoute exact path="/user-role/add" component={UserRolesForm} layout={Default} />
                    <UserRoute exact path="/user-role/edit/:id?" component={UserRolesForm} layout={Default} />

                    {/* Category */}
                    <UserRoute exact path="/categories" component={CategoryList} layout={Default} />
                    <UserRoute exact path="/categories/add" component={CategoryForm} layout={Default} />
                    <UserRoute exact path="/categories/edit/:id?" component={CategoryForm} layout={Default} />

                    {/* Voucher */}
                    <UserRoute exact path="/vouchers" component={VoucherList} layout={Default} />
                    <UserRoute exact path="/vouchers/add" component={VoucherForm} layout={Default} />
                    <UserRoute exact path="/vouchers/edit/:id?" component={VoucherForm} layout={Default} />

                    <UserRoute exact path="/user-vouchers" component={UserGiftVouchers} layout={Default} />

                    {/* products */}
                    <UserRoute exact path="/products" component={ProductsList} layout={Default} />
                    <UserRoute exact path="/products/add" component={ProductsForm} layout={Default} />
                    <UserRoute exact path="/products/edit/:id?" component={ProductsForm} layout={Default} />

                    {/* Order */}
                    <UserRoute exact path="/order" component={OrderList} layout={Default} />

                    {/* TableBookingList */}
                    <UserRoute exact path="/table-booking" component={TableBookingList} layout={Default} />
                    <UserRoute exact path="/table-booking/add" component={TableBookingForm} layout={Default} />
                    <UserRoute exact path="/table-booking/edit/:id" component={TableBookingForm} layout={Default} />

                    {/* FunctionsBookingList */}
                    <UserRoute exact path="/functions-booking" component={FunctionsBookingList} layout={Default} />
                    <UserRoute exact path="/functions-booking/add" component={FunctionsBookingForm} layout={Default} />
                    <UserRoute exact path="/functions-booking/edit/:id" component={FunctionsBookingForm} layout={Default} />

                    {/* bottomless-brunch */}
                    <UserRoute exact path="/bottomless-brunch" component={BottomlessBrunchList} layout={Default} />
                    <UserRoute exact path="/bottomless-brunch/add" component={BottomlessBrunchForm} layout={Default} />
                    <UserRoute exact path="/bottomless-brunch/edit/:id" component={BottomlessBrunchForm} layout={Default} />

                    {/* banner */}
                    <UserRoute exact path="/banner" component={BannerList} layout={Default} />
                    <UserRoute exact path="/banner/add" component={BannerForm} layout={Default} />
                    <UserRoute exact path="/banner/edit/:id" component={BannerForm} layout={Default} />


                    {/* Setting */}
                    <UserRoute exact path="/setting" component={Setting} layout={Default} />
                    <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                    <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={Default} />
                    <UserRoute exact path="/setting/business-hours" component={BusinessHours} layout={Default} />
                    <UserRoute exact path="/setting/business-hours/add" component={AddEditBusinessHoursForm} layout={Default} />
                    <UserRoute exact path="/setting/business-hours/edit/:id?" component={AddEditBusinessHoursForm} layout={Default} />
                    <UserRoute exact path="/setting/menu" component={MenuSetting} layout={Default} />
                    <UserRoute exact path="/setting/table-booking" component={TableBooking} layout={Default} />

                    {/* Jobs */}
                    <UserRoute exact path="/job_listings" component={JobsListings} layout={Default} />
                    <UserRoute exact path="/user_applications" component={UserApplications} layout={Default} />

                    <UserRoute exact path="/" component={Dashboard} layout={Default} />
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
